
var isSplide = document.getElementsByClassName('splide');
if (isSplide.length > 0) {
  //console.log('Splide Loaded')
  import("./components/splide-component" /* webpackChunkName: "splide-component" */).then(instance => {
    instance.initSplide();
  });
}


  var isCartComponent = document.getElementsByClassName('cartComponent');
  if (isCartComponent.length > 0) {
  import("./components/cart-component" /* webpackChunkName: "cart-component" */).then(instance => {
    instance.initCart();
  });
}


  var isVoucherThumb = document.getElementsByClassName('voucherThumb');
  if (isVoucherThumb.length > 0) {
  import("./components/checkout-component" /* webpackChunkName: "checkout-component" */).then(instance => {
    instance.initCheckout();
  });
}

var isProductComponent = document.getElementsByClassName('productComponent');
if (isProductComponent.length > 0) {

  import("./components/product-component" /* webpackChunkName: "product-component" */).then(instance => {
    instance.initProduct();
  });
}



/*  var isFancyTitle = document.getElementsByClassName('fancy_title');
  if (isFancyTitle.length > 0) {
  import("./components/lettering-component" /!* webpackChunkName: "lettering-component" *!/).then(instance => {
    instance.initLettering();
  });
}*/

import LazyLoad from 'vanilla-lazyload';

var lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
});

lazyLoadInstance.update();

/*import $ from 'jquery';
window.$ = window.jQuery = $;*/

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();



/*var searchSubmitBtn = document.getElementById('js-search');
var input = document.getElementById('search');
if (searchSubmitBtn.length > 0) {
  searchSubmitBtn.addEventListener('click', function (event) {
    var term = input.value;
    var gtag_event = {
      search_term: term,
    };
    gtag("event", "search", gtag_event);
  })
}*/



import Mmenu from 'mmenu-js';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#mobileMenu')) {
    new Mmenu(
      '#mobileMenu',
      {
        theme: 'dark',
        navbars: {
          use: false,
        },

        setSelected: {
          hover: true,
        },
      },
      {}
    );
  }
});


